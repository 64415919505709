import React from "react"

import config from "../data/config"
import Body from '../components/layout/default/main'

export default () => (
    <Body>
        <div>
            <div>
                <h2>What is this site?</h2>
                <p>kadebom.com is the personal website of a technologist that contains useful tools, files to download, and interesting topics about technology or other random things.</p>
                <p>We have...</p>
                <ul>
                    <li>JSON Linter</li>
                </ul>
                <p>We WILL have...</p>
                <ul>
                    <li>Bare Bones WebRTC Client</li>
                    <li>Completely Free Video Confrencing Client</li>
                    <li>Completely Free Chat client!</li>
                    <li>Mortage Calculator</li>
                    <li>United States Debt Clock</li>
                    <li>Completely Free Chat client!</li>
                    <li>Baby Gender Calculator</li>
                    <li>Astrology Zodiac Signs</li>
                    <li>Timezone Difference Calculator</li>
                    <li>Unixtimestamp Converter</li>
                </ul>
            </div>
            <div>
                <h2>About the Webmaster</h2>
                <p>I'm a autodidactic, musician, entrepenuer, tinkerer, wizard, and software engineer</p>
                <p>Typically, you can find me hacking away at some project I'll probably never finish, Smokin' BBQ, or contemplating the deep mysteries of life.</p>
                <ul>
                    <li><a href={config.twitterUrl}>Twitter</a></li>
                    <li><a href={config.linkedinUrl}>Linkedin</a></li>
                </ul>
            </div>
        </div>
    </Body>
)
